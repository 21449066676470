export function canModify() {
  return window.sspAuth && window.sspAuth.keycloak ? window.sspAuth.keycloak.hasResourceRole("DomainModifier", "BayWF-RedirectService") : false;
}

export function canView() {
  return window.sspAuth && window.sspAuth.keycloak ? window.sspAuth.keycloak.hasResourceRole("DomainReader", "BayWF-RedirectService") : false;
}

export function getRedirectorIPs() {
  return process.env.REACT_APP_REDIRECTOR_IPS.split(",");
}