import React from 'react';

import { Route, HashRouter as Router } from 'react-router-dom'

import Overview from './Overview';
import Domain from './Domain';

import { canView } from "./util/index";

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles';

// Page-specific styling
// The Rules table should have a minimum size
const useStyles = makeStyles({
  pBox: {
    marginTop: 25,
    marginBottom: 25
  }
});

export default function App() {
  const classes = useStyles();

  return (
    <div className="App">
      <header className="App-header">
        <h1>Vanity Domain Redirector</h1>
        { canView() ?
          <Router>
            <div>
              <Route exact path="/" component={Overview} />
              <Route path="/domain/:id" component={Domain} />
            </div>
          </Router>
        :
          <div>
            <Alert severity="warning" style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '20vh'}}>
              <Box component="span" display="block" className={classes.pBox}>
                You do not seem to have permission to access the Redirector. 
              </Box>
              <Box component="span" display="block" className={classes.pBox}>
                  If you feel this is wrong, please reach out to
                  the support team via the <a href="https://support.baywsf.com/helpdesk/Tickets/New?categoryId=417068&subject=WSF%20Redirector%20Access%20request&body=WSF%20Redirector%20Access%20request" target="blank">Website Factory Helpdesk</a>, using the 
                  &quot;WSF Access Request&quot; category. 
              </Box>
            </Alert>          
          </div>
        }
      </header>
    </div>
  );
}