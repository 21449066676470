import {config} from 'dotenv';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ConfirmProvider } from 'material-ui-confirm';

import * as serviceWorker from './serviceWorker';

import App from './App'

import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { onError } from 'apollo-link-error';

import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import CssBaseline from '@material-ui/core/CssBaseline';

// Pull from dotenv.
config();

window.initFE = (sspAuth) => {
  // The rendering of the DOM tree is going to happen after a successful initialization of the Authentication against Keycloak.
  sspAuth.events.once('auth-success', () => {
    // console.log("SSP Client init: Auth Success");

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );

      if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const client = new ApolloClient({
      uri: process.env.REACT_APP_API_URL,
      request: (operation) => {
        // Use the auth token from the WSF Header
        const token = window.sspAuth.getStoredToken('access');
        operation.setContext({
          headers: {
            authorization: token ? `Bearer ${token}` : ''
          }
        });
      },
      link: errorLink
    });

    ReactDOM.render(
      <React.StrictMode>
        <ApolloProvider client={client}>
          <ConfirmProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <App />
            </ThemeProvider>
          </ConfirmProvider>
        </ApolloProvider>
      </React.StrictMode>,
      document.getElementById('root')
    );
  });
};



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
