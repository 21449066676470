import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from "react-router-dom";

import { canModify, getRedirectorIPs } from "./util/index";

import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from "graphql-tag";

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useConfirm } from 'material-ui-confirm';
import Box from '@material-ui/core/Box'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Alert from '@material-ui/lab/Alert';

// GraphQL query to read the details of the specified domain
const READ_DOMAIN_DETAILS = gql`
  query getDomain($name: String!){
    domain(name: $name) {
      hostname,
      rules {
        name,
        __typename,
        ... on RegexRedirect {
          name,
          regex,
          pattern
        }
      },
      sslStatus {
        enabled,
        certValid
      },
      dnsStatus {
        addresses,
        connected
      }
    }
  }
`;

// GraphQL mutation to update the domain with its rules
const UPDATE_DOMAIN_DETAILS = gql`
  mutation SetupDomain($name: String!, $rules: [Rule!], $enableSSL: Boolean) {
    setupDomain(name: $name, rules: $rules, enableSSL: $enableSSL) {hostname}
  }
`;

// GraphQL mutation to remove a domain from the system
const REMOVE_DOMAIN = gql`
  mutation RemoveDomain($name: String!) {
    removeDomain(name: $name)
  }
`;

// Page-specific styling
// The Rules table should have a minimum size
const useStyles = makeStyles({
  table: {
    minWidth: 1000,
  },
  pBox: {
    marginTop: 25,
    marginBottom: 25
  }
});

// We expect the domain ID to be given is as a matched param
Domain.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  })
}

/**
 * React Component to represent the Domain details page. Built in hooks-style.
 * 
 * @param {*} props
 */
function Domain(props) {
  const { params } = props.match

  const classes = useStyles();

  // Setup the Apollo GraphQL queries and mutations
  const { data, loading, error, refetch } = useQuery(READ_DOMAIN_DETAILS, { 
    variables: { name: params.id },
    pollInterval: 5000
  });
  const [ updateDomain ] = useMutation(UPDATE_DOMAIN_DETAILS);
  const [ removeDomain ] = useMutation(REMOVE_DOMAIN);

  // The Add Rule dialog
  const [openAddDialog, setOpenAddDialog] = React.useState(false);
  const [ruleName, setRuleName] = React.useState("Match All, preserve path and query string");
  const [regex, setRegex] = React.useState("^http.?:\\/\\/([^\\/]+)\\/(.*)$");
  const [replacement, setReplacement] = React.useState("https://[TARGETDOMAIN]/${2}"); // eslint-disable-line no-template-curly-in-string

  // The Edit Rule dialog
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [existingRule, setExistingRule] = React.useState(null);

  const targetIPs = getRedirectorIPs(); // IPs of the Redirector Service

  let history = useHistory(); // To navigate back to the overview

  const confirm = useConfirm(); // Confirmation Dialogs

  const handleAddRedirectRule = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleAdd = () => {
    setOpenAddDialog(false);

    console.log(ruleName);

    var newRules = data.domain.rules.map( function (rule) {  
      return {
        name: rule.name,
        pattern: rule.pattern,
        regex: rule.regex
    }})

    newRules.push({
      name: ruleName,
      pattern: replacement,
      regex: regex
    });

    updateDomain({
      variables: {
        name: data.domain.hostname,
        rules: newRules
      }
    }).then(() => refetch()).catch(error => alert("Rule add not successful, please retry. (" + error.message + ")"))
  };

  const removeRule = (rule) => {
    confirm({ description: 'Do you really want to remove this rule? This action is not revertible.' })
    .then(() => {
      let newRules = data.domain.rules.filter(testRule => testRule !== rule).map( function (rule) {  
        return {
          name: rule.name,
          pattern: rule.pattern,
          regex: rule.regex
      }});

      updateDomain({
        variables: {
          name: data.domain.hostname,
          rules: newRules
        }
      }).then(() => refetch()).catch(error => alert("Rule removal not successful, please retry. (" + error.message + ")"))
    })
  };

  const handleRemoveDomain = () => {
    confirm({ description: 'Do you really want to remove this domain from the Redirect Service? This action is not revertible.' })
      .then(() => {
        removeDomain({
          variables: {
            name: data.domain.hostname
          }
        }).catch(error => alert("Deletion not successful, please retry. (" + error.message + ")"))

        // After we removed the domain, leave this page and navigate back to the overview
        history.push("/");

        window.location.reload(false);
      })
  }

  const editRule = (rule) => {
    let editRule = {
      name: rule.name,
      pattern: rule.pattern,
      regex: rule.regex,
      originalRule: rule
    }
    setExistingRule(editRule);

    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleSaveEditedRule = () => {
    console.log(existingRule.originalRule);

    let newRules = data.domain.rules.map( rule => {
      if( rule === existingRule.originalRule ) {
        return {
          name: rule.name,
          pattern: existingRule.pattern,
          regex: existingRule.regex
        }
      }
      else {
        return {
          name: rule.name,
          pattern: rule.pattern,
          regex: rule.regex
        }
      }
    })

    updateDomain({
      variables: {
        name: data.domain.hostname,
        rules: newRules
      }
    }).then(() => refetch()).catch(error => alert("Update not successful, please retry. (" + error.message + ")"))

    setOpenEditDialog(false);
  };

  const handleEnableSSL = () => {
    confirm({ 
      title:'Confirm SSL Enablement', 
      description: 'Your domain is connected to the Redirector. SSL Setup is possible. The Setup of the SSL certificate will normally take 1-2 hrs. Please be aware! Confirm to proceed with SSL enablement now:' 
    }).then(() => {
      updateDomain({
        variables: {
          name: data.domain.hostname,
          enableSSL: true
        }
      }).then(() => refetch()).catch(error => alert("Update not successful, please retry. (" + error.message + ")"))
    })
  };

  const handleDisableSSL = () => {
    confirm({
      title:'Confirm SSL Disabling',
      description: 'Please confirm to disable SSL for this domain'
    }).then(() => {
      updateDomain({
        variables: {
          name: data.domain.hostname,
          enableSSL: false
        }
      }).then(() => refetch()).catch(error => alert("Update not successful, please retry. (" + error.message + ")"))
    })
  }

  if (loading) return <p>loading...</p>;
  if (error) return <p>ERROR</p>;
  if (!data) return <p>Not found</p>;  

  return (
        <div>
          <Box component="span" display="block" className={classes.pBox}>
            Domain (Hostname): {data.domain.hostname}
          </Box>
          <Box component="span" display="block" className={classes.pBox}>
            {/*data.domain.addresses.length > 0 
              ?
                <div>
                  DNS setup: Domain resolves to {data.domain.addresses.join( ", ")}
                </div>
              :
                <div>
                  DNS setup: No setup yet.
                </div>
            */}
            {data.domain.dnsStatus.connected ? 
                <Alert severity="success"><b>DNS Status: Connected.</b> The Domain is connected to the service. The rules below apply.</Alert> 
              : 
                <Alert severity="info"><b>DNS Status: Unconnected.</b> To connect the domain to the Redirect service, setup an A record to any (ideally all) of these Redirector IPs: {targetIPs.join(" / ")}</Alert>
              }
            
            {data.domain.sslStatus.enabled ?
              data.domain.sslStatus.certValid ?
                <Alert severity="success"><b>SSL Status: Enabled, certificate valid.</b></Alert>
                :
                <Alert severity="warning"><b>SSL Status: Enabled, but certificate is currently invalid.</b></Alert>
              :
              data.domain.dnsStatus.connected ?
                <Alert severity="info"><b>SSL Status: Disabled.</b> To setup SSL, use the SSL button below.</Alert>
                :
                <Alert severity="info"><b>SSL Status: Disabled.</b> Connect the domain to the Redirector first, to be able to setup SSL</Alert>
            }
          </Box>
          <Box component="span" display="block" className={classes.pBox}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
              { canModify() &&
                  <Button variant="outlined" color="secondary" onClick={handleRemoveDomain}>Remove Domain from Service</Button>
                }
              </Grid>
              <Grid item xs={4} align="center">
              { canModify() && 
                data.domain.sslStatus.enabled ?
                <Button variant="outlined" color="secondary" onClick={handleDisableSSL}>Disable SSL</Button>
                :
                <Button variant="contained" color="primary" disabled={!data.domain.dnsStatus.connected} onClick={handleEnableSSL}>Enable SSL</Button>
              }              
              </Grid>
              <Grid item xs={4} align="right">
              { canModify() &&
                  <Button variant="contained" color="primary" onClick={handleAddRedirectRule}>Add Rule</Button>
                }
              </Grid>
            </Grid>
          </Box>
          <Box component="span" display="block" className={classes.pBox}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Rule</TableCell>
                    <TableCell>Rule type</TableCell>
                    <TableCell>Regex</TableCell>
                    <TableCell>Target pattern</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.domain.rules.map((rule) => (
                    <TableRow key={rule.name}>
                      <TableCell component="th" scope="row">
                        {rule.name}
                      </TableCell>
                      <TableCell>{rule.__typename}</TableCell>
                      <TableCell>{rule.regex}</TableCell>
                      <TableCell>{rule.pattern}</TableCell>
                      <TableCell align="right">
                        { canModify() &&
                          <div>
                            <Button variant="text" color="primary" onClick={() => editRule(rule)}>
                              Edit
                            </Button>
                            <Button variant="text" color="primary" onClick={() => removeRule(rule)}>
                              Remove
                            </Button>
                          </div>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box component="span" display="block" className={classes.pBox}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Button variant="outlined" color="primary" component={Link} to={"/"}>Back to Overview</Button>
              </Grid>
              <Grid item xs={6} align="right">
              </Grid>
            </Grid>
          </Box>
          <Dialog open={openAddDialog} onClose={handleCloseAddDialog} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Redirect Rule</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To add another Redirect Rule, please enter the respective data below.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Rule name"
                fullWidth
                defaultValue={ruleName}
                onChange = {event => setRuleName(event.target.value)}
              />
              <TextField
                margin="dense"
                id="pattern"
                label="Regex Pattern"
                fullWidth
                defaultValue={regex}
                onChange = {event => setRegex(event.target.value)}
              />
              <TextField
                margin="dense"
                id="replacement"
                label="Replacement"
                fullWidth
                defaultValue={replacement}
                onChange = {event => setReplacement(event.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAddDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleAdd} color="primary">
                Add
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openEditDialog} onClose={handleCloseEditDialog} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit Redirect Rule</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You can edit your Redirect Rule below.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Rule name"
                fullWidth
                defaultValue={existingRule ? existingRule.name : ""}
                disabled={true}
              />
              <TextField
                margin="dense"
                id="pattern"
                label="Regex Pattern"
                fullWidth
                defaultValue={existingRule ? existingRule.regex : ""}
                onChange = {event => { existingRule.regex = event.target.value }}
              />
              <TextField
                margin="dense"
                id="replacement"
                label="Replacement"
                fullWidth
                defaultValue={existingRule ? existingRule.pattern : ""}
                onChange = {event => { existingRule.pattern = event.target.value }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseEditDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSaveEditedRule} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </div>
  )
}

export default Domain